class Elementor_Testimonial{
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial.instance) {
            Elementor_Testimonial.instance = new Elementor_Testimonial();
        }
        return Elementor_Testimonial.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-testimonials.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
                let $content = $scope.find('.elementor-testimonial-content');
                let $itemactive = $scope.find('.owl-item.active.center .column-item');
                let $id = $itemactive.data('trigger');
                if($id.length > 0) {
                    $($id).fadeIn().addClass('active');
                }
                $carousel.on('changed.owl.carousel', function (event) {
                    $($content).fadeOut().removeClass('active');
                    let current = event.item.index;
                    let hash = $(event.target).find(".owl-item").eq(current).find(".column-item").data('trigger');
                    if(hash.length > 0) {
                        $(hash).fadeIn().addClass('active');
                    }
                });
            }
        } );
    }
}


Elementor_Testimonial.getInstance();